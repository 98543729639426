<template>
    <div>
        <div class="fl-x-cc">
            <h4 class="text-seventh">Favourites</h4>
        </div>
        <div>
            <validated-input placeholder="search" type="text" :border-radius="1" v-model="search"></validated-input>
        </div>
        <template v-if="data!=''">
        <div class="font-proxima-bold text-center text-dark b-1 mb-3" v-for="(item,index) in data" :key="index">
            <p class="pt-3" @click="$router.push('/individual-diseases/'+item.id+'/')"> {{item.disease}} </p>
        </div>
        </template>

       <div class="fl-x fl-j-c" v-else>

           <div class="fl-x" v-if="loading">
              <loading-animation></loading-animation>
             <p>Loading...</p>
          </div>
                   <found-item v-else></found-item>
       </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';
import FoundItem from '../disease/FoundItem';

export default {
    name: 'Favourites',
    components: { FoundItem },
    watch: {
        search (newVal, oldVal) {
            console.log(newVal, oldVal);
            this.searchItem();
        }
    },
    data () {
        return {
            loading: false,
            startSearch: false,
            data: [],
            listURL: urls.userHome.userHomeFavourites.list,
            search: ''
        };
    },
    mounted () {
        this.fetchItem();
    },
    methods: {
        async fetchItem () {
            console.log('Fetching...');
            try {
                this.loading = true;
                const response = await axios.get(this.listURL);
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async searchItem () {
            this.startSearch = true;
            console.log('Searching...');
            if (this.search === undefined) {
                this.search = '';
            }
            try {
                this.loading = true;
                const response = await axios.form(this.listURL, { search: this.search });
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }

};
</script>

<style scoped>

</style>
