import { render, staticRenderFns } from "./FavouritesList.vue?vue&type=template&id=12976c00&scoped=true&"
import script from "./FavouritesList.vue?vue&type=script&lang=js&"
export * from "./FavouritesList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12976c00",
  null
  
)

export default component.exports